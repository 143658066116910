import "./styles.scss"

import React from "react"

const Nav = ({ step, input }) => {
  const nav_data = [
    {
      label: "Dane podstawowe",
    },
    {
      label: "Dane kontaktowe",
    },
    {
      label: "Termin spotkania",
      disabled:
        parseInt(input?.sharesTotal?.replace(",00 zł", "")) >= 30000
          ? false
          : true,
    },
    {
      label: "Podsumowanie umowy",
    },
    { label: "Płatność", desc: "Zawarcie umowy" },
    { label: "Transakcja złożona" },
  ]

  return (
    <section className="order-nav">
      <div className="container-fluid">
        <div className="order-nav__wrapper">
          {nav_data
            // ?.filter(item => item !== null)
            .map((item, index) => (
              <p
                key={index}
                className={`${
                  step === index + 1
                    ? "current"
                    : step > index + 1
                    ? "done"
                    : ""
                }${item?.disabled ? " disabled" : ""}`}
              >
                0{index + 1}
                <br />
                <span>{item?.label}</span>
                {item?.desc && step !== 6 && <strong>{item?.desc}</strong>}
              </p>
            ))}
        </div>
      </div>
    </section>
  )
}

export default Nav
