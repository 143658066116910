import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"

import { isEmpty } from "lodash"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"

import { Content } from "page_components/order"

import CustomerProvider from "context/customer"
import { isUserLoggedIn } from "utils/functions"

const Order = () => {
  const title = "Transakcja"
  const [loggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    const auth = isUserLoggedIn()

    if (!isEmpty(auth)) {
      setLoggedIn(true)
    }

    !auth && navigate("/logowanie?order=true")
  }, [loggedIn])

  return (
    <Layout
      seo={{
        title: title,
      }}
      isOrder
    >
      <span className="anchor" id="top"></span>
      <CustomerProvider>
        <Breadcrumbs title={title} />
        <Content />
      </CustomerProvider>
    </Layout>
  )
}

export default Order
