import "./styles.scss"

import React from "react"
import { withPrefix } from "gatsby"

const OrderDocs = ({ input }) => {
  return parseInt(input?.sharesTotal?.replace(",00 zł", "")) >= 30000 ? (
    input.productId === 1441 ? (
      <>
        <li>
          <a
            href={withPrefix("/projekt-23/TIP_23_umowa_poreczenia.pdf")}
            target="_blank"
            rel="noreferrer"
          >
            Załącznik nr 1 - UMOWA PORĘCZENIA
          </a>
        </li>
        <li>
          <a
            href={withPrefix("/projekt-23/TIP_23_umowa_zastawu.pdf")}
            target="_blank"
            rel="noreferrer"
          >
            Załącznik nr 2 - UMOWA ZASTAWU
          </a>
        </li>
        <li>
          <a
            href={withPrefix(
              "/projekt-23/TIP_23_formularz_podst_informacji.pdf"
            )}
            target="_blank"
            rel="noreferrer"
          >
            Załącznik nr 3 - FORMULARZ PODSTAWOWYCH INFORMACJI
          </a>
        </li>
      </>
    ) : (
      <>
        <li>
          <a
            href={withPrefix("/projekt-23/TIP_23_umowa_poreczenia.pdf")}
            target="_blank"
            rel="noreferrer"
          >
            Załącznik nr 1 - UMOWA PORĘCZENIA
          </a>
        </li>
        <li>
          <a
            href={withPrefix("/projekt-23/TIP_23_umowa_zastawu.pdf")}
            target="_blank"
            rel="noreferrer"
          >
            Załącznik nr 2 - UMOWA ZASTAWU
          </a>
        </li>
        <li>
          <a
            href={withPrefix(
              "/projekt-23/TIP_23_formularz_podst_informacji.pdf"
            )}
            target="_blank"
            rel="noreferrer"
          >
            Załącznik nr 3 - FORMULARZ PODSTAWOWYCH INFORMACJI
          </a>
        </li>
      </>
    )
  ) : (
    <>
      <li>
        <a
          href={withPrefix("/projekt-23/TIP_23_formularz_podst_informacji.pdf")}
          target="_blank"
          rel="noreferrer"
        >
          Załącznik nr 1 - FORMULARZ PODSTAWOWYCH INFORMACJI
        </a>
      </li>
    </>
  )
}

export default OrderDocs
