import "./styles.scss"

import React, { useState } from "react"
import Calendar from "react-calendar"
import OutsideClickHandler from "react-outside-click-handler"
import moment from "moment"

const DateInput = ({ placeholder, name, value, label, onChange, error }) => {
  const [datePicker, setDatePicker] = useState(false)
  const todayMoment = moment()

  const weekDays = ["N", "Pn", "Wt", "Śr", "Cz", "Pt", "So"]
  const convertDate = date =>
    date ? `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}` : ""

  return (
    <div className="input input--date">
      {label && <label className="input__label">{label}</label>}
      <input
        className="input__input"
        placeholder={placeholder}
        name={name}
        value={value}
        onFocus={() => setDatePicker(true)}
        autoComplete="off"
      />
      {error === "error" && (
        <div className="input--date__error">Pole wymagane</div>
      )}
      {datePicker && (
        <OutsideClickHandler onOutsideClick={() => setDatePicker(false)}>
          <div className="react-calendar__container">
            <Calendar
              prevLabel={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                >
                  <path
                    fill="#fff"
                    d="M.328 9.442l8.162 7.25c1.119.995 2.79-.622 1.666-1.62L4.058 9.655c5.94-.004 5.87-.01 11.81-.015 1.52-.001 1.52-2.293 0-2.292-5.87.005-5.732.01-11.602.015l5.926-5.426C11.3.922 9.632-.695 8.526.317L.328 7.82c-.451.414-.463 1.209 0 1.62z"
                  />
                </svg>
              }
              nextLabel={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                >
                  <path
                    fill="#fff"
                    d="M16.672 7.558L8.51.308C7.39-.688 5.72.93 6.844 1.927l6.098 5.417c-5.94.004-5.87.01-11.81.015-1.52.001-1.52 2.293 0 2.292 5.87-.005 5.732-.01 11.602-.015l-5.926 5.426c-1.108 1.015.56 2.632 1.666 1.62l8.198-7.504c.451-.414.463-1.209 0-1.62z"
                  />
                </svg>
              }
              minDetail="decade"
              formatShortWeekday={(locale, date) =>
                `${weekDays[date.getDay()]}`
              }
              minDate={todayMoment.clone().add(7, "days")._d}
              tileDisabled={({ date, view }) =>
                view === "month" && (date.getDay() === 0 || date.getDay() === 6)
              }
              onChange={date => {
                onChange(convertDate(date))
                setDatePicker(false)
              }}
            />
          </div>
        </OutsideClickHandler>
      )}
    </div>
  )
}

export default DateInput
