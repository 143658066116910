import React from "react"

import Input from "components/Input"

const Individual = ({ input, setInput, validationErrors }) => {
  return (
    <div className="order-basic-data__form">
      <div className="row">
        <div className="col-sm-6">
          <Input
            type="text"
            label="Pierwsze imię*"
            value={input.firstName}
            onChange={e => setInput({ ...input, firstName: e.target.value })}
            error={validationErrors.firstName}
          />
        </div>
        <div className="col-sm-6">
          <Input
            type="text"
            label="Drugie imię"
            value={input.secondFirstName}
            onChange={e =>
              setInput({ ...input, secondFirstName: e.target.value })
            }
          />
        </div>
      </div>
      <Input
        type="text"
        label="Nazwisko*"
        value={input.lastName}
        onChange={e => setInput({ ...input, lastName: e.target.value })}
        error={validationErrors.lastName}
      />
      <Input
        type="number"
        label="Numer PESEL Inwestora* (11 cyfr)"
        value={input.pesel}
        onChange={e => setInput({ ...input, pesel: e.target.value })}
        error={validationErrors.pesel}
        errorMessage="Proszę wprowadzić dokładnie 11 cyfr"
      />
      <Input
        type="text"
        label="Numer i seria dowodu osobistego Inwestora*"
        value={input.idNumber}
        onChange={e => setInput({ ...input, idNumber: e.target.value })}
        error={validationErrors.idNumber}
      />
      <Input
        type="number"
        label="Numer telefonu Inwestora* (wyłącznie cyfry)"
        min="0"
        value={input.phone}
        onChange={e => setInput({ ...input, phone: e.target.value })}
        error={validationErrors.phone}
        errorMessage="Proszę wprowadzić same cyfry"
      />
      <Input
        type="number"
        label="Rachunek bankowy Inwestora* (26 cyfr)"
        value={input.bankAccount}
        onChange={e => setInput({ ...input, bankAccount: e.target.value })}
        error={validationErrors.bankAccount}
        errorMessage="Proszę wprowadzić dokładnie 26 cyfr"
      />
      <Input
        type="number"
        label="Numer Identyfikacji Podatkowej (NIP) (10 cyfr)"
        min="0"
        value={input.nip}
        onChange={e => {
          e.target.value >= 0 && setInput({ ...input, nip: e.target.value })
        }}
        error={validationErrors.nip}
        errorMessage="Proszę wprowadzić dokładnie 10 cyfr"
      />
      <small>
        [wypełnić w przypadku osoby fizycznej prowadzącej działalność
        gospodarczą]
      </small>
      <Input
        type="text"
        label="Urząd Skarbowy właściwy dla Inwestora*"
        value={input.taxOffice}
        onChange={e => setInput({ ...input, taxOffice: e.target.value })}
        error={validationErrors.taxOffice}
      />
      {/* <Input
        type="text"
        label="Liczba nowo utworzonych udziałów w podwyższonym kapitale zakładowym Dewelopera zaproponowanych do objęcia Inwestorowi"
        value={input.sharesQuantity}
        readOnly
      />
      <Input
        type="text"
        label="Cena nominalna jednego udziału"
        value={input.sharePrice}
        readOnly
      />
      <Input
        type="text"
        label="Cena emisyjna jednego udziału"
        value={input.shareEmissionPrice}
        readOnly
      /> */}
      <Input
        type="text"
        label="Łączna kwota udzielonej pożyczki inwestycyjnej"
        value={input.sharesEmissionTotal}
        readOnly
      />
    </div>
  )
}

export default Individual
